<div class="container-fluid" style="margin-top: 100px">
  <div class="row">
    <div class="col-md-12">
      <!-- <img alt="web" src="../../assets/img/m10.png" width="80px" /> -->
      <h2>Custom Logo Design Service</h2>
      <p class="text-justify">
        Whether you are starting a new business or simply rebranding an existing
        one, a fresh, modern, and effective logo design is a critical component
        of your brand’s success. We want to deliver a beautiful logo for your
        brand using our logo maker, with its unique ability to generate
        thousands of professional logo designs and build a matching website to
        fully establish your online brand.
      </p>
      <p class="text-justify">
        With an extensive bank of different icons stretching across dozens of
        various industries, measuring in the thousands, and custom fonts and
        colours, you will be certain to find the perfect logo to start your new
        business or fit in with existing branding. Whether you are a startup
        company, a consultant, managing a side gig, a blog, an online store, or
        an internet personality, our diverse and customizable logo maker will
        enable you to jumpstart your brand
      </p>
    </div>
    <!-- <div class="col-sm-4">
      <img alt="web" src="../../assets/img/Custom-Web-Application.png" />
    </div> -->
  </div>

  <div class="container" style="margin-top: 100px; margin-bottom: 50px">
    <div class="text-center margin-bottom1">
      <h4>Choose The Best Plan That’s For You</h4>
    </div>
    <div class="row">
      <div class="col-md-4 margin-top1">
        <div class="card text-center">
          <div class="title">
            <i class="fa fa-rocket" aria-hidden="true"></i>
            <h2>Basic</h2>
          </div>
          <div class="price">
            <h4>
              ₹ 300
              <!-- <span style="font-size: 20px">/month</span> -->
            </h4>
          </div>
          <div class="option">
            <ul>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Team of Project
                Head & 1 Dedicated Designer
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>2 Telephonic
                Consultation with Project Head
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>3 Design Samples
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>5 Revision Rounds
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Copyright
                Ownership Transfer of Selected Design
              </li>
            </ul>
          </div>
          <a
            routerLink="/payment"
            [queryParams]="{ name: 'Basic Logo', amount: 300 }"
            >Pay Now</a
          >
        </div>
      </div>
      <div class="col-md-4 margin-top1">
        <div class="card text-center">
          <div class="title">
            <i class="fa fa-rocket" aria-hidden="true"></i>
            <h2>Standard</h2>
          </div>
          <div class="price">
            <h4>
              ₹ 1,500
              <!-- <span style="font-size: 20px">/month</span> -->
            </h4>
          </div>
          <div class="option">
            <ul>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Team of Project
                Head & 1 Dedicated Designer
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>2 Telephonic
                Consultation with Project Head
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>5 Design Samples
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>10 Revision Rounds
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Copyright
                Ownership Transfer of Selected Design
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>1 Intro Video for
                Selected Design
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>CMYK Colour Design
              </li>
            </ul>
          </div>
          <a
            routerLink="/payment"
            [queryParams]="{ name: 'Standard Logo', amount: 1500 }"
            >Pay Now</a
          >
        </div>
      </div>
      <div class="col-md-4 margin-top1">
        <div class="card text-center">
          <div class="title">
            <i class="fa fa-rocket" aria-hidden="true"></i>
            <h2>Premium</h2>
          </div>
          <div class="price">
            <h4>
              ₹ 2,500
              <!-- <span style="font-size: 20px">/month</span> -->
            </h4>
          </div>
          <div class="option">
            <ul>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Team of Project
                Head & 1 Dedicated Designer
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>5 Telephonic
                Consultation with Project Head
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>5 Design Samples
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Unlimited Revision
                Rounds
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Copyright
                Ownership Transfer of Selected Design
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>1 Intro Video for
                Selected Design
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>CMYK Colour Design
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Presentation on
                Realistic Product Mockup
              </li>
            </ul>
          </div>
          <a
            routerLink="/payment"
            [queryParams]="{ name: 'Premium Logo', amount: 2500 }"
            >Pay Now</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
