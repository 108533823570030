import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorageService } from "angular-web-storage";
import * as uuid from "uuid";

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.css"],
})
export class PaymentComponent implements OnInit {
  step = 1;
  spinner = true;

  name;
  amount;

  orders;

  orderName;
  orderId;
  orderAmount;
  orderDate;

  // orders = [
  //   {
  //     name: "Basic Logo",
  //     amount: 300,
  //     status: "SUCCESS",
  //   },
  //   {
  //     name: "Premium Logo",
  //     amount: 2500,
  //     status: "SUCCESS",
  //   },
  // ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private local: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((paramMap) => {
      this.name = paramMap.get("name");
      this.amount = paramMap.get("amount");
    });

    this.orders = this.local.get("ORDERS");

    setTimeout(() => {
      this.spinner = false;
    }, 2000);

    setTimeout(() => {
      const mappedArray = this.orders.map((el) => {
        return {
          name: el.name,
          amount: el.amount,
          orderId: el.orderId,
          date: el.date,
          isOrderCancelled: el.isOrderCancelled,
          status:
            el.status === "PROCESSING"
              ? "SUCCESS"
              : el.status === "REFUNDED"
              ? "REFUNDED"
              : "SUCCESS",
        };
      });

      this.orders = mappedArray;
      this.local.set("ORDERS", this.orders);
    }, 20000);
  }

  onCancel() {
    this.router.navigateByUrl("/logo-design");
  }

  onNext() {
    this.spinner = true;
    setTimeout(() => {
      this.spinner = false;
    }, 2000);

    const object = {
      name: this.name,
      amount: this.amount,
      orderId: uuid.v4(),
      date: new Date().getTime(),
      isOrderCancelled: false,
      status: "PROCESSING",
    };

    if (!this.orders || !this.orders.length) {
      this.orders = [];
    }

    this.orders.push(object);

    this.local.set("ORDERS", this.orders);

    this.step = 2;
  }

  onCancelOrder(obj) {
    this.spinner = true;
    setTimeout(() => {
      this.spinner = false;
    }, 2000);

    this.orderId = obj.orderId;
    this.orderName = obj.name;
    this.orderAmount = obj.amount;
    this.orderDate = obj.date;

    this.step = 3;
  }

  goBackToOrder() {
    this.step = 2;
  }

  onRefund(obj) {
    this.spinner = true;

    setTimeout(() => {
      this.spinner = false;
    }, 2000);

    this.orderId = obj.orderId;
    this.orderName = obj.orderName;
    this.orderAmount = obj.orderAmount;
    this.orderDate = obj.orderDate;

    const orders = this.local.get("ORDERS");

    const objIndex = orders.findIndex((el) => el.orderId == obj.orderId);

    orders[objIndex].status = "REFUNDED";
    orders[objIndex].isOrderCancelled = true;

    this.local.set("ORDERS", orders);

    this.step = 4;
  }

  goBack() {
    this.step = 1;
  }
}
