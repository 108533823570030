<section class="page-section">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-12" data-animation="fadeInRight">
        <h2 class="title">WE MAKE DIGITAL BEAUTIFUL</h2>
        <p style="font-size: 25px">
          <span style="color: #5f8bbc"> Grow Your </span>Digital Presence
        </p>
        <p>
          We work with our clients to get Digital marketing growth that makes
          them smile.
        </p>

        <p>
          Your website in not on the frontline on search engines? Every business
          owner wants the website to be on top of the list of all the search
          engines result page. SEO is the best way to stand out in the market,
          promoting brand awareness and online marketing. Would you like to
          drive qualified organic traffic to your website? We provide SEO
          solutions to gain the quality number of traffic to your website
          through organic search.
        </p>
      </div>

      <div class="col-sm-6 col-md-6">
        <img
          src="assets/img/VGT-Digital-Marketing1.png"
          width=""
          height=""
          alt=""
          title=""
        />
      </div>
    </div>
  </div>
</section>

<section id="clients" class="page-section device">
  <div class="container">
    <div class="row">
      <p style="font-size: 25px">
        <span style="margin-left: 10px"> Services We Offer</span>
      </p>
      <br />

      <div class="col-md-12 text-center">
        <div
          class="owl-carousel navigation-1"
          data-pagination="false"
          data-items="5"
          data-autoplay="true"
          data-navigation="true"
        >
          <a href="#">
            <img src="assets/img/m2.png" width="" height="" alt="" />
          </a>
          <a href="#">
            <img src="assets/img/m3.png" width="" height="" alt="" />
          </a>
          <a href="#">
            <img src="assets/img/m4.png" width="" height="" alt="" />
          </a>
          <a href="#">
            <img src="assets/img/m5.png" width="" height="" alt="" />
          </a>
          <a href="#">
            <img src="assets/img/m6.png" width="" height="" alt="" />
          </a>
          <a href="#">
            <img src="assets/img/m7.png" width="" height="" alt="" />
          </a>
          <a href="#">
            <img src="assets/img/m8.png" width="" height="" alt="" />
          </a>
          <a href="#">
            <img src="assets/img/m9.png" width="" height="" alt="" />
          </a>
          <a href="#">
            <img src="assets/img/m10.png" width="" height="" alt="" />
          </a>
          <a href="#">
            <img src="assets/img/m11.png" width="" height="" alt="" />
          </a>
          <a href="#">
            <img src="assets/img/m12.png" width="" height="" alt="" />
          </a>
          <a href="#">
            <img src="assets/img/m13.png" width="" height="" alt="" />
          </a>
          <a href="#">
            <img src="assets/img/m14.png" width="" height="" alt="" />
          </a>
          <a href="#">
            <img src="assets/img/m15.png" width="" height="" alt="" />
          </a>
          <a href="#">
            <img src="assets/img/m16.png" width="" height="" alt="" />
          </a>
          <a href="#">
            <img src="assets/img/m17.png" width="" height="" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="container mobile">
  <div class="row">
    <p style="font-size: 25px">
      <span style="margin-left: 10px"> Services We Offer</span>
    </p>
    <br />
    <div class="">
      <!-- Carousel
              ================================================== -->
      <div id="myCarousel" class="carousel slide">
        <div class="carousel-inner">
          <div class="item active">
            <div class="row" style="margin: auto; width: 1050px">
              <div class="col-md-12 col-lg-3 col-sm-12 mb5">
                <div class="">
                  <img src="assets/img/m2.png" alt="Slide11" />
                </div>
              </div>
              <div class="col-md-12 col-lg-3 col-sm-12 mb5">
                <div class="">
                  <img src="assets/img/m3.png" alt="Slide12" />
                  <div class="caption">
                    <!-- <h3>Product label</h3> -->
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-3 col-sm-12 mb5">
                <div class="">
                  <img src="assets/img/m4.png" alt="Slide12" />
                  <div class="caption">
                    <!-- <h3>Product label</h3> -->
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-3 col-sm-12 mb5">
                <div class="">
                  <img src="assets/img/m5.png" alt="Slide12" />
                  <div class="caption"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="row" style="margin: auto; width: 1050px">
              <div class="col-md-12 col-lg-3 col-sm-12 mb5">
                <div class="">
                  <img src="assets/img/m6.png" alt="Slide11" />
                </div>
              </div>
              <div class="col-md-12 col-lg-3 col-sm-12 mb5">
                <div class="">
                  <img src="assets/img/m7.png" alt="Slide12" />
                  <div class="caption">
                    <!-- <h3>Product label</h3> -->
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-3 col-sm-12 mb5">
                <div class="">
                  <img src="assets/img/m8.png" alt="Slide12" />
                  <div class="caption">
                    <!-- <h3>Product label</h3> -->
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-3 col-sm-12 mb5">
                <div class="">
                  <img src="assets/img/m9.png" alt="Slide12" />
                  <div class="caption">
                    <!-- <h3>Product label</h3> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="row" style="margin: auto; width: 1050px">
              <div class="col-md-12 col-lg-3 col-sm-12 mb5">
                <div class="">
                  <img src="assets/img/m10.png" alt="Slide11" />
                </div>
              </div>
              <div class="col-md-12 col-lg-3 col-sm-12 mb5">
                <div class="">
                  <img src="assets/img/m11.png" alt="Slide12" />
                  <div class="caption"></div>
                </div>
              </div>
              <div class="col-md-12 col-lg-3 col-sm-12 mb5">
                <div class="">
                  <img src="assets/img/m12.png" alt="Slide12" />
                  <div class="caption">
                    <!-- <h3>Product label</h3> -->
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-3 col-sm-12 mb5">
                <div class="">
                  <img src="assets/img/m13.png" alt="Slide12" />
                  <div class="caption">
                    <!-- <h3>Product label</h3> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="row" style="margin: auto; width: 1050px">
              <div class="col-md-12 col-lg-3 col-sm-12 mb5">
                <div class="">
                  <img src="assets/img/m14.png" alt="Slide11" />
                </div>
              </div>
              <div class="col-md-12 col-lg-3 col-sm-12 mb5">
                <div class="">
                  <img src="assets/img/m15.png" alt="Slide12" />
                  <div class="caption"></div>
                </div>
              </div>
              <div class="col-md-12 col-lg-3 col-sm-12 mb5">
                <div class="">
                  <img src="assets/img/m16.png" alt="Slide12" />
                  <div class="caption">
                    <!-- <h3>Product label</h3> -->
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-3 col-sm-12 mb5">
                <div class="">
                  <img src="assets/img/m17.png" alt="Slide12" />
                  <div class="caption">
                    <!-- <h3>Product label</h3> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a class="left carousel-control" href="#myCarousel" data-slide="prev"
          ><i class="fa fa-chevron-left fa-2x"></i
        ></a>
        <a class="right carousel-control" href="#myCarousel" data-slide="next"
          ><i class="fa fa-chevron-right fa-2x"></i
        ></a>

        <ol class="carousel-indicators">
          <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
          <li data-target="#myCarousel" data-slide-to="1"></li>
          <li data-target="#myCarousel" data-slide-to="2"></li>
        </ol>
      </div>
      <!-- End Carousel -->
    </div>
    <!-- End Well -->
  </div>
</div>

<div class="container" style="margin-top: 100px; margin-bottom: 50px">
  <div class="text-center margin-bottom1">
    <h4>Choose The Best Plan That’s For You</h4>
  </div>
  <div class="row">
    <div class="col-md-4 margin-top1">
      <div class="card text-center">
        <div class="title">
          <i class="fa fa-rocket" aria-hidden="true"></i>
          <h2>Basic</h2>
        </div>
        <div class="price">
          <h4>₹ 15,000<span style="font-size: 20px">/month</span></h4>
        </div>
        <div class="option">
          <ul>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>20 social media
              posts
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Any 3 Social Media
              Platforms
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>1000 Free Facebook
              Advert Credits for Page Likes & Promoted Posts per month
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Custom Creatives per
              month
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Onboarding Call with
              Social Media Marketing Expert
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Integration of
              Social Media Platforms, if required
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Social Media
              Platforms - Audit & Remedial Action
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Comprehensive Social
              Media Content Calendar
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Unique content,
              specifically created for your business
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Scheduling &
              Publishing
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Enhanced Social
              Media Visibility by use of relevant Hashtags
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Content seeding into
              relevant communities to increase visibility
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Performance Report
              with Analytics
            </li>
          </ul>
        </div>
        <a href="#">Pay Now</a>
      </div>
    </div>
    <div class="col-md-4 margin-top1">
      <div class="card text-center">
        <div class="title">
          <i class="fa fa-rocket" aria-hidden="true"></i>
          <h2>Standard</h2>
        </div>
        <div class="price">
          <h4>₹ 25,000<span style="font-size: 20px">/month</span></h4>
        </div>
        <div class="option">
          <ul>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>30 social media
              posts
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Any 4 Social Media
              Platforms
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>2000 Free Facebook
              Advert Credits for Page Likes & Promoted Posts per month
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Custom Creatives per
              month
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Onboarding Call with
              Social Media Marketing Expert
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Integration of
              Social Media Platforms, if required
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Social Media
              Platforms - Audit & Remedial Action
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Comprehensive Social
              Media Content Calendar
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Unique content,
              specifically created for your business
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Scheduling &
              Publishing
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Enhanced Social
              Media Visibility by use of relevant Hashtags
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Content seeding into
              relevant communities to increase visibility
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Performance Report
              with Analytics
            </li>
          </ul>
        </div>
        <a href="#">Pay Now</a>
      </div>
    </div>
    <div class="col-md-4 margin-top1">
      <div class="card text-center">
        <div class="title">
          <i class="fa fa-rocket" aria-hidden="true"></i>
          <h2>Premium</h2>
        </div>
        <div class="price">
          <h4>₹ 35,000<span style="font-size: 20px">/month</span></h4>
        </div>
        <div class="option">
          <ul>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>50 social media
              posts
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Any 4 Social Media
              Platforms
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>3000 Free Facebook
              Advert Credits for Page Likes & Promoted Posts per month
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Custom Creatives per
              month
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Dedicated
              Relationship Manager
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Onboarding Call with
              Social Media Marketing Expert
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Integration of
              Social Media Platforms, if required
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Social Media
              Platforms - Audit & Remedial Action
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Comprehensive Social
              Media Content Calendar
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Unique content,
              specifically created for your business
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Scheduling &
              Publishing
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Enhanced Social
              Media Visibility by use of relevant Hashtags
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Content seeding into
              relevant communities to increase visibility
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Performance Report
              with Analytics
            </li>
          </ul>
        </div>
        <a href="#">Pay Now</a>
      </div>
    </div>
  </div>
</div>
