import { Component } from '@angular/core';

@Component({
  selector: 'app-email-and-office',
  templateUrl: './email-and-office.component.html',
  styleUrls: ['./email-and-office.component.css']
})
export class EmailAndOfficeComponent {

}
