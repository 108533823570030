<div class="centerFlex" style="margin-top: 20px" *ngIf="step == 1">
  <div
    class="card"
    style="height: 800px; width: 600px; background-color: white"
  >
    <div class="spinner" *ngIf="spinner"></div>
    <div class="card-body" style="text-align: center" *ngIf="!spinner">
      <div
        (click)="goBackToOrder()"
        style="
          text-align: end;
          color: blue;
          margin-right: 10px;
          cursor: pointer;
          text-decoration: underline;
        "
      >
        Order History ->
      </div>
      <h4 style="color: black; font-weight: bold">Payment</h4>
      <p style="color: black; margin-top: 10px">
        Scan the following QR code to pay
      </p>
      <div>
        <img src="../../assets/img/qr-code.jpeg" alt="qr-code" width="300px" />
      </div>
      <div class="product">
        <p>
          Product Name: <span style="font-weight: bold">{{ name }}</span>
        </p>
        <p>
          Amount:<span style="font-weight: bold">Rs. {{ amount }}</span>
        </p>
      </div>
      <div style="margin-top: 20px">
        <p style="font-size: 15px; color: black; font-weight: bold">
          After successfull payment click on next button
        </p>
      </div>
      <div class="centerFlex">
        <button class="btn first" (click)="onCancel()">Cancel</button>
        <button class="btnn second" (click)="onNext()">Next</button>
      </div>
    </div>
  </div>
</div>

<div
  class="centerFlex"
  style="margin-top: 20px; margin-bottom: 20px"
  *ngIf="step == 2"
>
  <div class="card parent">
    <div class="spinner" *ngIf="spinner"></div>
    <div class="card-body" style="text-align: center" *ngIf="!spinner">
      <div
        (click)="goBack()"
        style="
          text-align: start;
          color: blue;
          margin-left: 10px;
          cursor: pointer;
          text-decoration: underline;
        "
      >
        Go Back
      </div>
      <h4 style="color: black; font-weight: bold">Orders</h4>

      <div *ngFor="let data of orders">
        <div
          style="
            background-color: bisque;
            color: black;
            border: 2px solid black;
            margin-right: 20px;
            margin-left: 20px;
            font-size: 10px;
            margin-top: 20px;
          "
        >
          <p>
            Order Id:
            <span style="font-weight: bold; font-size: 10px">{{
              data.orderId
            }}</span>
          </p>
          <p>
            Product Name: <span style="font-weight: bold">{{ data.name }}</span>
          </p>
          <p>
            Amount: <span style="font-weight: bold">Rs. {{ data.amount }}</span>
          </p>

          <p>
            Date:
            <span style="font-weight: bold">{{
              data.date | date : "dd/MM/yyyy"
            }}</span>
          </p>
          <p>
            Status:
            <span
              [ngStyle]="
                data.status === 'PROCESSING'
                  ? { color: '#FFAA33' }
                  : data.status === 'SUCCESS'
                  ? { color: 'green' }
                  : { color: 'red' }
              "
              style="font-weight: bold; color: green; font-size: 15px"
              >{{ data.status }}</span
            >
          </p>
          <div
            *ngIf="data.status != 'REFUNDED'"
            style="font-weight: bold; font-size: 10px; color: red"
          >
            You can cancel the order within 24 hours only
          </div>
          <div class="centerFlex" *ngIf="data.status != 'REFUNDED'">
            <button
              class="btn first"
              (click)="
                onCancelOrder({
                  orderId: data.orderId,
                  name: data.name,
                  amount: data.amount,
                  date: data.date
                })
              "
            >
              Cancel Order
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="centerFlex"
  style="margin-top: 20px; margin-bottom: 20px"
  *ngIf="step == 3"
>
  <div class="card parent">
    <div class="spinner" *ngIf="spinner"></div>
    <div class="card-body" style="text-align: center" *ngIf="!spinner">
      <h4 style="color: black; font-weight: bold">Refund</h4>
      <div style="color: black">
        <div style="margin-bottom: 20px">
          <img src="../../assets/img/warning.png" width="50px" />
        </div>
        <p style="color: red; font-weight: bold">
          Are you sure you want to cancel the order ?
        </p>
        <div
          style="
            background-color: bisque;
            color: black;
            border: 2px solid black;
            margin-right: 20px;
            margin-left: 20px;
            font-size: 10px;
            margin-top: 20px;
            margin-bottom: 20px;
          "
        >
          <p>
            Order Id:
            <span style="font-weight: bold; font-size: 10px">{{
              orderId
            }}</span>
          </p>
          <p>
            Product Name: <span style="font-weight: bold">{{ orderName }}</span>
          </p>
          <p>
            Amount: <span style="font-weight: bold">Rs. {{ orderAmount }}</span>
          </p>

          <p>
            Date:
            <span style="font-weight: bold">{{
              orderDate | date : "dd/MM/yyyy"
            }}</span>
          </p>
        </div>

        <p
          class="blink-soft"
          style="
            color: orangered;
            font-weight: bold;
            font-size: 15px;
            margin: 20px;
          "
        >
          After cancelling order amount will be credited to your account within
          5 minutes
        </p>
        <div class="centerFlex">
          <button class="btnn second" (click)="goBackToOrder()">
            No, Go back
          </button>
          <button
            class="btn first"
            (click)="onRefund({orderId, orderName,orderAmount, orderDate})"
          >
            Yes, Cancel Order
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="centerFlex"
  style="margin-top: 20px; margin-bottom: 20px"
  *ngIf="step == 4"
>
  <div class="card parent">
    <div class="spinner" *ngIf="spinner"></div>
    <div class="card-body" style="text-align: center" *ngIf="!spinner">
      <h4 style="color: black; font-weight: bold">Refund</h4>
      <div style="color: black">
        <div style="margin-bottom: 20px">
          <img src="../../assets/img/green_tick.png" width="50px" />
        </div>
        <p style="color: green; font-weight: bold">
          Your following order has been cancelled successfully
        </p>
        <div
          style="
            background-color: bisque;
            color: black;
            border: 2px solid black;
            margin-right: 20px;
            margin-left: 20px;
            font-size: 10px;
            margin-top: 20px;
            margin-bottom: 20px;
          "
        >
          <p>
            Order Id:
            <span style="font-weight: bold; font-size: 10px">{{
              orderId
            }}</span>
          </p>
          <p>
            Product Name: <span style="font-weight: bold">{{ orderName }}</span>
          </p>
          <p>
            Amount: <span style="font-weight: bold">Rs. {{ orderAmount }}</span>
          </p>

          <p>
            Date:
            <span style="font-weight: bold">{{
              orderDate | date : "dd/MM/yyyy"
            }}</span>
          </p>
        </div>

        <p
          style="color: green; font-weight: bold; font-size: 15px; margin: 20px"
        >
          Amount <span style="color: brown">Rs. {{ orderAmount }}</span> will be
          credited to your account within 5 minutes
        </p>
        <div class="centerFlex">
          <button class="btnn second" (click)="goBackToOrder()">
            Go to Order History
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
