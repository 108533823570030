import { Component } from '@angular/core';

@Component({
  selector: 'app-aws-and-azure',
  templateUrl: './aws-and-azure.component.html',
  styleUrls: ['./aws-and-azure.component.css']
})
export class AwsAndAzureComponent {

}
