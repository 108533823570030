<div class="container-fluid" style="margin-top: 100px">
  <div class="row">
    <div class="col-md-8">
      <!-- <img alt="web" src="../../assets/img/m10.png" width="80px" /> -->
      <h2>Microsoft office 365 India</h2>
      <p class="text-justify">
        Get the best of Microsoft Office 365 for your business. With Office 365
        plans in India, you get features like secure cloud storage,
        collaboration tools, business intelligence and more. Upgrade your
        business with the power of Office 365 today.
      </p>
      <p class="text-justify">
        Stay connected no matter where you are with Microsoft Office 365! With
        features like cloud storage, real-time collaboration, and more, it's
        never been easier to work together on important projects. Create, edit
        and share documents with your team from anywhere in the world. Get
        Office 365 now and start connecting!
      </p>
    </div>
    <div class="col-sm-4">
      <img alt="web" src="../../assets/img/office-apps.png" />
    </div>
  </div>

  <div class="container" style="margin-top: 100px; margin-bottom: 50px">
    <div class="text-center margin-bottom1">
      <h4>Choose The Best Plan That’s For You</h4>
    </div>
    <div class="row">
      <div class="col-md-4 margin-top1">
        <div class="card text-center">
          <div class="title">
            <i class="fa fa-rocket" aria-hidden="true"></i>
            <h2>Basic</h2>
          </div>
          <div class="price">
            <h4>₹ 135<span style="font-size: 20px">/month</span></h4>
          </div>
          <div class="option">
            <ul>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Web and mobile
                versions of Office apps
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Email and
                calendaring
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>File storage and
                sharing
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Teamwork and
                communication
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Security and
                compliance
              </li>
            </ul>
          </div>
          <a href="#">Pay Now</a>
        </div>
      </div>
      <div class="col-md-4 margin-top1">
        <div class="card text-center mt-5">
          <div class="title">
            <i class="fa fa-rocket" aria-hidden="true"></i>
            <h2>Standard</h2>
          </div>
          <div class="price">
            <h4>₹ 660<span style="font-size: 20px">/month</span></h4>
          </div>
          <div class="option">
            <ul>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Desktop versions
                of Office apps with premium features
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Email and
                calendaring
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>File storage and
                sharing
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Teamwork and
                communication
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Security and
                compliance
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Manage customer
                appointments
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Attendee
                registration and reporting tools
              </li>
            </ul>
          </div>
          <a href="#">Pay Now</a>
        </div>
      </div>
      <div class="col-md-4 margin-top1">
        <div class="card text-center">
          <div class="title">
            <i class="fa fa-rocket" aria-hidden="true"></i>
            <h2>Premium</h2>
          </div>
          <div class="price">
            <h4>₹ 1,440<span style="font-size: 20px">/month</span></h4>
          </div>
          <div class="option">
            <ul>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Desktop and mobile
                versions of Office apps
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Email and
                calendaring
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>File storage and
                sharing
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Teamwork and
                communication
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Access and data
                control
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Cyberthreat
                protection
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Advanced security
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Security and
                compliance
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Manage customer
                appointments
              </li>
              <!-- <li>
                <i class="fa fa-check" aria-hidden="true"></i>Attendee
                registration and reporting tools
              </li> -->
            </ul>
          </div>
          <a href="#">Pay Now</a>
        </div>
      </div>
    </div>
  </div>
</div>
