<section class="page-section">
  <div class="container">
    <div class="section-title white">
      <h2 class="title">VIDEO CREATION</h2>
    </div>
    <div class="row">
      <!-- <div class="col-md-4 col-sm-6 text-center" data-animation="fadeInLeft">
                    <img src="img/sections/mobile-img1.png" width="590" height="415" alt="" />
              </div> -->
      <div class="col-md-12 col-sm-12" data-animation="fadeInRight">
        <h5>1. Product video and corporate Video</h5>
        <p>
          Corporate Video films are the new generation form of content that is
          highly effective, in order to attract the attention of your target
          audience. A corporate video film provides a glimpse of your offerings,
          business ideas, approach and other necessary information that you want
          to convey to your target audience. In addition to corporate videos, we
          also work on product videos with product content, animation, VoiceOver
          and music features.
        </p>
        <h5>2. Doodles( Whiteboard Videos)</h5>
        <p>Doodle Videos can help you improve your conversions in a big way!</p>
        <p>
          We are experts in creating videos along with Script writing, Doodle
          Video and VoiceOver. Our animation doodle videos are highly engaging
          and it can accelerate lead generations, increase conversions and
          improve sales. This is all done for significantly lesser cost of
          traditional videos.
        </p>
      </div>
    </div>
  </div>
</section>

<div class="container" style="margin-top: 20px; margin-bottom: 50px">
  <div class="text-center margin-bottom1">
    <h4>Choose The Best Plan That’s For You</h4>
  </div>
  <div class="row">
    <div class="col-md-4 margin-top1">
      <div class="card text-center">
        <div class="title">
          <i class="fa fa-rocket" aria-hidden="true"></i>
          <h2>Basic</h2>
        </div>
        <div class="price">
          <h4>
            ₹ 3,000
            <!-- <span style="font-size: 20px">/month</span> -->
          </h4>
        </div>
        <div class="option">
          <ul>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Sound design & audio
              editing
            </li>
            <li><i class="fa fa-check" aria-hidden="true"></i>Video editing</li>
          </ul>
        </div>
        <a href="#">Pay Now</a>
      </div>
    </div>
    <div class="col-md-4 margin-top1">
      <div class="card text-center">
        <div class="title">
          <i class="fa fa-rocket" aria-hidden="true"></i>
          <h2>Standard</h2>
        </div>
        <div class="price">
          <h4>
            ₹ 8,000
            <!-- <span style="font-size: 20px">/month</span> -->
          </h4>
        </div>
        <div class="option">
          <ul>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Sound design & audio
              editing
            </li>
            <li><i class="fa fa-check" aria-hidden="true"></i>Video editing</li>
            <li><i class="fa fa-check" aria-hidden="true"></i>Animation</li>
          </ul>
        </div>
        <a href="#">Pay Now</a>
      </div>
    </div>
    <div class="col-md-4 margin-top1">
      <div class="card text-center">
        <div class="title">
          <i class="fa fa-rocket" aria-hidden="true"></i>
          <h2>Premium</h2>
        </div>
        <div class="price">
          <h4>
            ₹ 10,000
            <!-- <span style="font-size: 20px">/month</span> -->
          </h4>
        </div>
        <div class="option">
          <ul>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Sound design & audio
              editing
            </li>
            <li><i class="fa fa-check" aria-hidden="true"></i>Video editing</li>
            <li><i class="fa fa-check" aria-hidden="true"></i>Animation</li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Motion graphics &
              graphic design
            </li>
          </ul>
        </div>
        <a href="#">Pay Now</a>
      </div>
    </div>
  </div>
</div>
