import { Component } from '@angular/core';

@Component({
  selector: 'app-server-and-cloud',
  templateUrl: './server-and-cloud.component.html',
  styleUrls: ['./server-and-cloud.component.css']
})
export class ServerAndCloudComponent {

}
