<div class="container-fluid" style="margin-top: 100px">
  <div class="row">
    <div class="col-md-8">
      <!-- <img alt="web" src="../../assets/img/m10.png" width="80px" /> -->
      <h2>AWS & Microsoft Azure Cloud Service</h2>
      <p class="text-justify">
        Get reliable and secure cloud services with AWS Managed Cloud. Our team
        of experts offers complete setup, configuration, and management of your
        cloud environment. Rely on us to handle the heavy lifting so you can
        focus on growing your business.
      </p>
      <p class="text-justify">
        Microsoft Azure Cloud services to help you maximize your business's
        efficiency and performance. Microsoft Azure Service is a robust platform
        that offers a wide range of features and benefits to help businesses
        succeed.
      </p>
    </div>
    <div class="col-sm-4">
      <img alt="web" src="../../assets/img/aws-and-azure.jpg" />
    </div>
  </div>

  <div class="container" style="margin-top: 100px; margin-bottom: 50px">
    <div class="text-center margin-bottom1">
      <h4>Choose The Best Plan That’s For You</h4>
    </div>
    <div class="row">
      <div class="col-md-4 margin-top1">
        <div class="card text-center">
          <div class="title">
            <i class="fa fa-rocket" aria-hidden="true"></i>
            <h2>Basic</h2>
          </div>
          <div class="price">
            <h4>₹ 3,000<span style="font-size: 20px">/month</span></h4>
          </div>
          <div class="option">
            <ul>
              <li><i class="fa fa-check" aria-hidden="true"></i>4 GB RAM</li>
              <li><i class="fa fa-check" aria-hidden="true"></i>2 CPU Core</li>
              <li><i class="fa fa-check" aria-hidden="true"></i>80 GB SSD</li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>1 Ip Address
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>5 TB Bandwidth
              </li>
            </ul>
          </div>
          <a href="#">Pay Now</a>
        </div>
      </div>
      <div class="col-md-4 margin-top1">
        <div class="card text-center mt-5">
          <div class="title">
            <i class="fa fa-rocket" aria-hidden="true"></i>
            <h2>Standard</h2>
          </div>
          <div class="price">
            <h4>₹ 5,000<span style="font-size: 20px">/month</span></h4>
          </div>
          <div class="option">
            <ul>
              <li><i class="fa fa-check" aria-hidden="true"></i>8 GB RAM</li>
              <li><i class="fa fa-check" aria-hidden="true"></i>2 CPU Core</li>
              <li><i class="fa fa-check" aria-hidden="true"></i>160 GB SSD</li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>1 Ip Address
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>5 TB Bandwidth
              </li>
            </ul>
          </div>
          <a href="#">Pay Now</a>
        </div>
      </div>
      <div class="col-md-4 margin-top1">
        <div class="card text-center">
          <div class="title">
            <i class="fa fa-rocket" aria-hidden="true"></i>
            <h2>Premium</h2>
          </div>
          <div class="price">
            <h4>₹ 8,000<span style="font-size: 20px">/month</span></h4>
          </div>
          <div class="option">
            <ul>
              <li><i class="fa fa-check" aria-hidden="true"></i>16 GB RAM</li>
              <li><i class="fa fa-check" aria-hidden="true"></i>4 CPU Core</li>
              <li><i class="fa fa-check" aria-hidden="true"></i>320 GB SSD</li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>1 Ip Address
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>6 TB Bandwidth
              </li>
            </ul>
          </div>
          <a href="#">Pay Now</a>
        </div>
      </div>
    </div>
  </div>
</div>
